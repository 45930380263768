//Form validation
$('.validated-form-button').click(function(event) {
	event.preventDefault();
	var sendForm = '';

	$(this).closest('form').find("select.required, input.required, textarea.required").each(function(index) {
		if($(this).attr('type') === 'checkbox')
		{
			if($(this).is(':checked')) {
				$(this).removeClass('error');
				sendForm += '';
			} else {
				$(this).addClass('error');
				sendForm += 'error';
			}	
		}
		else
		{
			if($(this).val() == "") {
				$(this).addClass('error');
				sendForm += 'error';
			} else {
				$(this).removeClass('error');
				sendForm += '';
			}
		}
	});

    if(sendForm == '') {
        $('#requiredFieldsNotice').hide();
        $(this).closest('.validated-form').submit();
    } else {
        $(this).before('<p id="requiredFieldsNotice" class="alert fail hide">Please fill in all required fields.</p>');
        $('#requiredFieldsNotice').show();
    }

});

//Add error class to required fields on blur
$("select.required, input.required, textarea.required").blur(function() {
	if($(this).val() == '') {
		$(this).addClass('error');
	} else {
		$(this).removeClass('error');
	}
});