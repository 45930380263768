// ======================================================================= 
// Header Menu
// =======================================================================

$('.header-nav-burger').click(function() {
    $('.header-nav').toggleClass('active');
});
$('.header-nav-close').click(function() {
    $('.header-nav').removeClass('active');
});

$('.header .menu-item-has-children').mouseenter(function(event) {
	event.stopPropagation();
	$(this).find('ul').show();
});
$('.header .menu-item-has-children').mouseleave(function() {
	$(this).find('ul').hide();
});



// =======================================================================
// Carousel with control nav - non slideshow
// =======================================================================
var crouselWithArrows = ".carousel-with-control-nav";

if($(crouselWithArrows).length > 0) {
	$(crouselWithArrows).flexslider({
		animation: "slide",
		slideshow: false,
		slideshowSpeed: 8000,
		controlNav: true,
		directionNav: false,
		animationLoop: true,
		touch: true,
		smoothHeight: false,
		after: function() {
			$('.carousel .flex-active-slide .wrapper').addClass('animated fadeIn');
		}
	});
}



// ======================================================================= 
// Google Maps
// =======================================================================

var center,
	icon = null,
	map = null,
	markersArray = [],
	circle = null,
	infowindow,
	locationMarker,
	marker,
	i;

var GoogleMap = {
	loadSingleMarkerMap: function(latitude, longitude, mapID) {

		if(mapID) {
			mapID = mapID;
		} else {
			mapID = 'googleMap';
		}

		var lat = (latitude ? latitude : 54.309313);
		var lng = (longitude ? longitude : -2.548828);
	    var infowindow = new google.maps.InfoWindow();

	    var map = new google.maps.Map(document.getElementById(mapID), {
			zoom: 17,
			center: new google.maps.LatLng(lat, lng),
			styles: [{"featureType":"administrative","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"administrative.province","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","elementType":"all","stylers":[{ visibility: "off" }]},{"featureType":"road","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"lightness":"30"}]},{"featureType":"road.local","elementType":"all","stylers":[{"lightness":"40"}]},{"featureType":"transit","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]},{"featureType":"water","elementType":"labels","stylers":[{"lightness":-25},{"saturation":-100}]}]
	    });

	    var icon = {
	        anchor: new google.maps.Point(13, 33),
	        scaledSize: new google.maps.Size(26, 37),
	        size: new google.maps.Size(26, 37),
	        url: '/wp-content/themes/hcb/images/icons/map-marker.svg'
	 	};

	 	marker = new google.maps.Marker({
			position: new google.maps.LatLng(lat, lng),
	        icon: icon,
			animation: google.maps.Animation.DROP,
			map: map
	    });

		google.maps.event.addListener(marker, 'click', (function(marker, i) {
			return function() {
				infowindow.setContent('<div class="tooltip"><p>' + document.getElementById('companyAddress').innerHTML + '</p></div>');
				infowindow.open(map, marker);
        	}
		})(marker, i));	    
	}
}

//Display single marker Google map
var singleMarkerGoogleMap = $('.google-map.single-marker');

if(singleMarkerGoogleMap.length) {
	singleMarkerGoogleMap.each(function() {
		var id = $(this).attr('id'),
			lat = $(this).attr('data-lat'),
			lng = $(this).attr('data-lng');

		if(lat && lng) {
			GoogleMap.loadSingleMarkerMap(lat, lng, id);
		}
	});
}